
/*! DBMNG v0.0.1 
 * Date: 2015-11-02
 */

/**
 * See README.md for requirements and usage info
 */

(function() { 
