
/////////////////////////////////////////////////////////////////////////////
// Dbmng
// ====================
/// Dbmng object.
/**
\param aObject array that holds the aForm and aParam array and other attributes
\return html
*/
var Dbmng = function() {

};

Dbmng.defaults = {};
